import { createContext, useContext, useState } from 'react'

import { useTranslation } from 'react-i18next'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@biogroup/ui/dialog'

import {
  ConsentForm,
  ConsentFormSubmit,
} from '#app/components/consent-form.tsx'

interface CreateAccountDialogContextType {
  open: boolean
  setOpen: (open: boolean) => void
}

const CreateAccountDialogContext = createContext<
  CreateAccountDialogContextType | undefined
>(undefined)

export function useCreateAccountDialog() {
  const context = useContext(CreateAccountDialogContext)
  if (!context) {
    throw new Error(
      'useCreateAccountDialog must be used within a CreateAccountDialogProvider',
    )
  }
  return context
}

export function CreateAccountDialogProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [open, setOpen] = useState(false)

  return (
    <CreateAccountDialogContext.Provider value={{ open, setOpen }}>
      {children}
    </CreateAccountDialogContext.Provider>
  )
}

export function CreateAccountDialog() {
  const { t } = useTranslation()
  const { open, setOpen } = useCreateAccountDialog()

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent closeLabel={t('cta.close')}>
        <DialogHeader>
          <DialogTitle>{t('createAccount.title')}</DialogTitle>
          <DialogDescription>{t('createAccount.disclaimer')}</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <ConsentForm
            renderSubmit={isLoading => (
              <ConsentFormSubmit isLoading={isLoading} />
            )}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
